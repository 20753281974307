export default class OmniMajorCategoryModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.OmniMajorCategoryId = '00000000-0000-0000-0000-000000000000';
      this.Code = '';
      this.Description = '';
    }
  }
  
  setModel(model){
    this.OmniMajorCategoryId = model.OmniMajorCategoryId;
    this.Code = model.Code;
    this.Description = model.Description;
  }
}