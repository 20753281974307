<template>
  <div class="wrapAll" id="app">
    <loading></loading>
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        langs: ['fr', 'en']
      };
    },
    computed: {
      locale: {
        get() {
          return this.$root.$i18n.locale;
        },
        set(value) {
          this.$root.$i18n.locale = value;
          let routeName = this.$route.name;
          const routeNameIndex = routeName.indexOf('.');
          if (~routeNameIndex) {
            routeName = `${value}.${routeName.slice(routeNameIndex + 1)}`;
            this.$router.push({ name: routeName });
          }
        }
      }
    },
    watch: {
      '$store.state.app.errors' () {
        this.$router.push({ name: this.$getLocalRouteName(this.$store.state.app.userInfo ? 'error' : 'login') });
      }
    }
  };
</script>

<style lang="scss">
    .wrapAll {
    flex: 1 0 auto;
  }
  #nav {
    padding: 30px;
    a

  {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active

  {
    color: #42b983;
  }

  }
  }

  .error {
    color: #ff5f5f;
  }

html body ::-moz-selection {
  background: #e20613!important;
  text-shadow: none;
}

html body ::selection {
  background: #e20613!important;
  text-shadow: none;
}
</style>
