import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';
import i18n from '@/i18n';
import Paginate from 'vuejs-paginate';
import {
  MdButton, MdTable, MdField, MdIcon, MdCard, MdContent, MdList, MdDivider, MdEmptyState, MdRipple, MdTabs,
  MdCheckbox, MdMenu, MdAutocomplete, MdHighlightText, MdProgress, MdDialog, MdDialogAlert, MdDialogConfirm, MdTooltip, MdSnackbar,
  MdSwitch
} from 'vue-material/dist/components';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.config.productionTip = false;

Vue.use(MdButton);
Vue.use(MdTable);
Vue.use(MdTooltip);
Vue.use(MdSnackbar);
Vue.use(MdField);
Vue.use(MdIcon);
Vue.use(MdCard);
Vue.use(MdContent);
Vue.use(MdList);
Vue.use(MdDivider);
Vue.use(MdEmptyState);
Vue.use(MdRipple);
Vue.use(MdCheckbox);
Vue.use(MdMenu);
Vue.use(MdAutocomplete);
Vue.use(MdHighlightText);
Vue.use(MdTabs);
Vue.use(MdProgress);
Vue.use(MdDialog);
Vue.use(MdDialogAlert);
Vue.use(MdDialogConfirm);
Vue.use(MdSwitch);
Vue.component('enum-select', () => import('./components/EnumSelect.vue'));
Vue.component('enum-select-vue-material', () => import('./components/EnumSelectVueMaterial.vue'));
Vue.component('provider-autocomplete-vue-material', () => import('./components/ProviderAutocompleteVueMaterial.vue'));
Vue.component('loading', () => import('./components/Loading.vue'));
Vue.component('paginate', Paginate);

Vue.prototype.$getLocalRouteName = function (routeName) {
  return i18n.locale + '.' + routeName;
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
