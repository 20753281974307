export default {
  addError(state, error) {
    state.errors.push({ message: error.message, stack: error.stack });
  },
  dismissError(state, error) {
    state.errors = state.errors.filter(x => x !== error);
  },
  stopLoading(state, key) {
    state.loadingState = state.loadingState.filter(keyValue => keyValue !== key);
  },
  startLoading(state, key) {
    if (!state.loadingState.some(keyValue => keyValue === key)) {
      state.loadingState.push(key);
    }
  },
  setUserInfo(state, value) {
    state.userInfo = value;
  }
};
