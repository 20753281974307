class ErrorCodeEnumWeb {
  constructor() {
    this.None = 0;
    this.GenericError = 500; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let errorCodeEnumWeb = new ErrorCodeEnumWeb();