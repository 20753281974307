export default {
  addError(state, error) {
    state.error = error;
  },
  setErrorProductAdded(state, result) {
    state.errorProductAdded = result;
  },
  setProductAdded(state, result) {
    state.isProductAdded = result;
  },
  setProductUpdated(state, result) {
    state.isProductUpdated = result;
  },
  setActiveProducts(state, result) {
    state.activeProducts = result;
  },
  setProductCount(state, result) {
    state.productCount = result;
  },
  setProductsDeleted(state, result) {
    state.isProductsDeleted = result;
  },
  setActiveProduct(state, result) {
    state.activeProduct = result;
  }
};
