<template>
  <div class="mainContent">
    <SideMenu v-if="!$route.meta.hideSidebar"></SideMenu>
    <div class="colRight" :class="{home:$route.meta.hideSidebar}">
      <router-view  class="contentRight"/>
    </div>
  </div>
</template>

<script>
  import SideMenu from './SideMenu.vue';

  export default {
    components: {
      SideMenu
    },
    name: 'Localize'
  };
</script>

<style scoped lang="scss">
  .mainContent{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
  }
  .colRight{
    min-width: 80%;
    max-width: 80%;
    flex: 0 1 80%;
    background-color:#fff;
  }
  .colRight.home{
    min-width: 100%;
    max-width: 100%;
    flex: 0 1 100%;
    background-color:#fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #f4f4f4;
  }
  .contentRight{
    width: 100%;
    height: 100%;
  }
  form.contentRight{
    width: 500px;
    height: auto;
    padding:1.00rem;
    background:#fff;
    padding: 3.000rem;
    border: 1px solid #dadada;
  }
</style>
