import actions from './actions.js';
import mutations from './mutations.js';
import State from './state.js';

export default {
  name: 'provider',
  module: {
    namespaced: true,
    state: new State(),
    actions,
    mutations
  }
};
