import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import product from './product';
import provider from './provider';
import tag from './tag';
import category from './category';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [app.name]: app.module,
    [product.name]: product.module,
    [provider.name]: provider.module,
    [tag.name]: tag.module,
    [category.name]: category.module
  }
});
