export default class OmniMinorCategoryModel {
  constructor(model) {
    if(model){
      this.setModel(model);
    }
    else {
      this.OmniMinorCategoryId = '00000000-0000-0000-0000-000000000000';
      this.OmniIntermediateCategoryId = '00000000-0000-0000-0000-000000000000';
      this.Code = '';
      this.Description = '';
    }
  }
  
  setModel(model){
    this.OmniMinorCategoryId = model.OmniMinorCategoryId;
    this.OmniIntermediateCategoryId = model.OmniIntermediateCategoryId;
    this.Code = model.Code;
    this.Description = model.Description;
  }
}