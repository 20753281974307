class MemberTypeEnumWeb {
  constructor() {
    this.Manager = 0;
    this.Buyer = 1; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let memberTypeEnumWeb = new MemberTypeEnumWeb();