import authenticatedAxios from '../../authenticatedAxios';
import { errorCodeEnumWeb } from '../../models/GeneratedModels/ErrorCodeEnumWeb.js';

export default {
  async GetAllProviders(context) {
    try {
      context.commit('app/startLoading', 'GetAllProviders', { root: true });
      let response = await authenticatedAxios.post('/api/provider/GetAllProviders');
      context.commit('setActiveProviders', response.data);
      context.commit('app/stopLoading', 'GetAllProviders', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetAllProviders', { root: true });
    }
  }
};
