<template>
  <div class="md-layout-item md-size-20 sideMenu">
    <div class="sticky">
      <div class="logo">
        <div class="wrapper">
          <img src="../assets/laferte-noir.png" />
        </div>
      </div>
      <md-content class="md-elevation-4 sideMenuContent">
        <md-list>
          <md-list-item v-if="false">
            <md-icon>playlist_add</md-icon>
            <span class="md-list-item-text" disabled>{{ $t('menu.dashboard') }}</span>
          </md-list-item>

          <md-list-item v-if="isMemberAllowed($router.resolve($t('routes.products')).route)" :to="$t('routes.products')">
            <md-icon>list</md-icon>
            <span class="md-list-item-text">{{ $t('menu.products') }}</span>
          </md-list-item>

          <!-- <md-list-item v-if="isMemberAllowed($router.resolve($t('routes.addProduct')).route)" :to="$t('routes.addProduct')">
            <md-icon>add_box</md-icon>
            <span class="md-list-item-text">{{ $t('menu.addProduct') }}</span>
          </md-list-item> -->


          <!-- <md-list-item v-if="isMemberAllowed($router.resolve($t('routes.productsPublicationStatus')).route)" :to="$t('routes.productsPublicationStatus')">
            <md-icon>publish</md-icon>
            <span class="md-list-item-text">{{ $t('menu.productsPublicationStatus') }}</span>
          </md-list-item> -->

          <md-list-item v-if="false">
            <md-icon>playlist_add</md-icon>
            <span class="md-list-item-text" disabled>{{ $t('menu.pendingProducts') }}</span>
          </md-list-item>

          <md-list-item v-if="false">
            <md-icon>playlist_add</md-icon>
            <span class="md-list-item-text" disabled>{{ $t('menu.exclusionsList') }}</span>
          </md-list-item>

          <md-list-item v-if="false">
            <md-icon>label</md-icon>
            <span class="md-list-item-text" disabled>{{ $t('menu.labels') }}</span>
          </md-list-item>

          <md-list-item v-if="isMemberAllowed($router.resolve($t('routes.productImportation')).route)" :to="$t('routes.productImportation')">
            <md-icon>post_add</md-icon>
            <span class="md-list-item-text">{{ $t('menu.productImportation') }}</span>
          </md-list-item>

          <md-list-item v-if="isMemberAllowed($router.resolve($t('routes.productsSummary')).route)" :to="$t('routes.productsSummary')">
            <md-icon>summarize</md-icon>
            <span class="md-list-item-text">{{ $t('menu.productsSummary') }}</span>
          </md-list-item>

          <!--<md-list-item :to="$t('routes.products')">
    <md-icon>business</md-icon>
    <span class="md-list-item-text" disabled>{{ $t('menu.providers') }}</span>
  </md-list-item>
  -->

          <md-list-item @click="importProductsInventory()" hidden>
            <md-icon>sync</md-icon>
            <span class="md-list-item-text">Reception price and quantity</span>
          </md-list-item>

          <md-list-item @click="logout">
            <md-icon>exit_to_app</md-icon>
            <span class="md-list-item-text">{{ $t('menu.logout') }}</span>
          </md-list-item>
        </md-list>
      </md-content>
    </div>
  </div>
</template>

<script>
  import { isMemberAllowed } from '@/router/routes';
  import i18n from '@/i18n';

  export default {
    name: 'SideMenu',
    methods: {
      isMemberAllowed,
      async logout() {
        await this.$store.dispatch('app/logout');
        this.$nextTick(() => {
          this.$router.push({ path: i18n.t('routes.login', i18n.locale) });
        });
      },
      importProductsInventory() {
        this.$store.dispatch('product/ImportProductsInventory');
      }
    }
  };
</script>

<style scoped lang="scss">
  .sideMenu{
    border-right: 1px solid #dadada;
    background: #f4f4f4;
  }
  .sideMenu .logo{
    background-color:#fff;
  }
  .sideMenu .logo .wrapper{
    max-width:200px;
    margin:0 auto;
    padding: 1.50rem 0;
  }
  .sideMenu .md-list{
    padding:0;
    border-top: 1px solid #dadada;
  }
  .sideMenuContent {
    height: 100%;
    box-shadow: none;
    border:0;
  }
  .sideMenu .sticky{
    position: sticky;
    top: 0;
  }
  .sideMenu .md-list-item{
    border-bottom: 1px solid #dadada;
  }
  .sideMenu .md-list-item a,
  .sideMenu .md-list-item button{
    border-left: 4px solid #fff;
  }
  .sideMenu .md-list-item a.router-link-active{
    border-left: 4px solid #e20613;
    color: #e20613;
  }
  .sideMenu .md-list-item-text{
    color: #2a2a2a;
    font-weight: 700;
  }
  .sideMenu .md-icon.md-theme-default.md-icon-font{
    color: #2a2a2a;
  }
</style>
