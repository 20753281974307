export default {
  setTags(state, result) {
    state.tags = result;
  },
  setActiveProducts(state, result) {
    state.activeProducts = result;
  },
  setProductCount(state, result) {
    state.productCount = result;
  },
  startLoading(state) {
    state.isLoading = true;
  },
  stopLoading(state) {
    state.isLoading = false;
  },
  setAssociateProductToTagSuccess(state) {
    state.isAssociateProductToTagSuccess = {
      isSuccess: true
    };
  }
};
