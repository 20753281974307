import store from './store';
import axios from 'axios';
import cookie from 'js-cookie';

const authenticatedAxios = axios.create();

authenticatedAxios.interceptors.request.use(config => {
  const token = cookie.getJSON('Authorization');
  config.headers.Authorization = `Bearer ${token.AccessToken}`;
  return config;
}, null);

authenticatedAxios.interceptors.response.use(null, async error => {
  if (error.config && error.response && error.response.status === 401) {
    const token = await store.dispatch('app/refresh');
    error.config.headers.Authorization = `Bearer ${token.AccessToken}`;

    try {
      const response = await axios.request(error.config);
      return Promise.resolve(response);
    } catch (innerError) {
      if (error.response && error.response.status === 401) {
        await store.dispatch('app/logout');
      }
      
      return Promise.reject(innerError);
    }
  }

  return Promise.reject(error);
});

export default authenticatedAxios;