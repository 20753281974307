import actions from './actions.js';
import mutations from './mutations.js';
import State from './state.js';

const name = 'category';

export default {
  name,
  module: {
    namespaced: true,
    state: new State(),
    actions,
    mutations
  }
};
