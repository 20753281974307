import authenticatedAxios from '../../authenticatedAxios';
import { errorCodeEnumWeb } from '../../models/GeneratedModels/ErrorCodeEnumWeb.js';

export default {
  async AddProduct(context, product) {
    try {
      context.commit('app/startLoading', 'AddProduct', { root: true });
      await authenticatedAxios.post('/api/product/AddProduct', product);
      context.commit('setProductAdded', { isUpdated: true });
      context.commit('app/stopLoading', 'AddProduct', { root: true });
    } catch (e) {
      // Hardcorded pour le moment puisqu'on a seulement 1 erreur 400 possible. Il faudra voir pour un meilleur pattern quand on en aura plusieurs
      if (e.response.status === 400) {
        context.commit('setErrorProductAdded', { isUpdated: true });
        context.commit('app/stopLoading', 'AddProduct', { root: true });
      } else {
        context.commit('app/addError', e.response.status, { root: true });
        context.commit('app/stopLoading', 'AddProduct', { root: true });
      }
    }
  },
  async UpdateProduct(context, product) {
    try {
      context.commit('app/startLoading', 'UpdateProduct', { root: true });
      await authenticatedAxios.post('/api/product/UpdateProduct', product);
      context.commit('setProductUpdated', { isUpdated: true });
      context.commit('app/stopLoading', 'UpdateProduct', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'UpdateProduct', { root: true });
    }
  },
  async GetAllProducts(context, [currentPage, pageSize, searchTerm, omniMajorCategoryId, statusCode, publishStatusCode, hasMissingWebFields, includeDeleted]) {
    try {
      context.commit('app/startLoading', 'GetAllProducts', { root: true });
      let response = await authenticatedAxios.post('/api/product/GetAllProducts', {
        CurrentPage: currentPage,
        HasMissingWebFields: hasMissingWebFields,
        PageSize: pageSize,
        SearchTerm: searchTerm,
        OmniMajorCategoryId: omniMajorCategoryId,
        StatusCode: statusCode,
        PublishStatusCode: publishStatusCode,
        IncludeDeleted: Boolean(includeDeleted)
      });
      context.commit('setActiveProducts', response.data.ProductModels);
      context.commit('setProductCount', response.data.ProductCount);
      context.commit('app/stopLoading', 'GetAllProducts', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetAllProducts', { root: true });
    }
  },
  async DeleteProducts(context, productIds) {
    try {
      context.commit('app/startLoading', 'DeleteProducts', { root: true });
      await authenticatedAxios.post('/api/product/DeleteProduct', productIds);
      context.commit('setProductsDeleted', { isUpdated: true });
      context.commit('app/stopLoading', 'DeleteProducts', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'DeleteProducts', { root: true });
    }
  },
  async UndeleteProduct(context, productToUndelete) {
    try {
      context.commit('app/startLoading', 'UndeleteProduct', { root: true });
      await authenticatedAxios.post('/api/product/UndeleteProduct', productToUndelete);
      context.commit('setProductsDeleted', { isUpdated: true });
      context.commit('app/stopLoading', 'UndeleteProduct', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'UndeleteProduct', { root: true });
    }
  },
  async DeleteImage(context, fileToDelete) {
    try {
      context.commit('app/startLoading', 'DeleteImage', { root: true });
      await authenticatedAxios.post('/api/product/DeleteImage', fileToDelete);
      context.commit('setImageDeleted', { isUpdated: true });
      context.commit('app/stopLoading', 'DeleteImage', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'DeleteImage', { root: true });
    }
  },
  async GetProduct(context, productId) {
    try {
      context.commit('app/startLoading', 'GetProduct', { root: true });
      let response = await authenticatedAxios.get('/api/product/GetProduct', { params: { ProductId: productId } });
      context.commit('setActiveProduct', response.data);
      context.commit('app/stopLoading', 'GetProduct', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetProduct', { root: true });
    }
  },
  async ImportPromotions(context) {
    try {
      context.commit('app/startLoading', 'ImportPromotions', { root: true });

      await authenticatedAxios.post('/api/product/ImportPromotions');
      context.commit('app/stopLoading', 'ImportPromotions', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'ImportPromotions', { root: true });
    }
  },
  async SynchronizeActivePromotions(context) {
    try {
      context.commit('app/startLoading', 'SynchronizeActivePromotions', { root: true });

      await authenticatedAxios.post('/api/product/SynchronizeActivePromotions');
      context.commit('app/stopLoading', 'SynchronizeActivePromotions', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'SynchronizeActivePromotions', { root: true });
    }
  }
};
