import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/i18n';
import routes, { getRouteName, isMemberAllowed } from '@/router/routes';
import store from '../store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  if (to.meta.ignoreAuthentication) {
    return next();
  } else {
    return store.dispatch('app/getInfo')
      .then(() => {
        if (isMemberAllowed(to)) {
          return next();
        } else {
          return next({ name: getRouteName(i18n.locale, 'login') });
        }
      })
      .catch(() => next({ name: getRouteName(i18n.locale, 'login') }));
  }
});

export default router;