import authenticatedAxios from '../../authenticatedAxios';
import axios from 'axios';
import cookie from 'js-cookie';

export default {
  async getInfo({ commit }) {
    const response = await authenticatedAxios.post('/api/getinfo');
    commit('setUserInfo', response.data);
  },
  async login({ dispatch }, { username, password }) {
    const response = await axios.post('/api/login', { username, password });
    cookie.set('Authorization', response.data);
    await dispatch('getInfo');
  },
  async refresh() {
    const token = cookie.getJSON('Authorization');
    const response = await axios.post('/api/refresh', { refreshToken: token.RefreshToken });
    cookie.set('Authorization', response.data);
    return response.data;
  },
  async logout({ commit }) {
    cookie.remove('Authorization');
    commit('setUserInfo', null);
  }
};
