import authenticatedAxios from '../../authenticatedAxios';
import { errorCodeEnumWeb } from '@/models/GeneratedModels/ErrorCodeEnumWeb.js';

export default {
  async GetTags(context) {
    try {
      context.commit('app/startLoading', 'GetTags', { root: true });
      let response = await authenticatedAxios.get('/api/tag/GetTags');
      context.commit('setTags', response.data);
      context.commit('app/stopLoading', 'GetTags', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetTags', { root: true });
    }
  },
  async GetProductWithTags(context, [currentPage, pageSize, omniMajorCategoryId, omniIntermediateCategoryId, omniMinorCategoryId, statusCode]) {
    try {
      context.commit('startLoading');
      let response = await authenticatedAxios.post('/api/product/GetProductWithTags', {
        CurrentPage: currentPage,
        PageSize: pageSize,
        OmniMajorCategoryId: omniMajorCategoryId,
        StatusCode: statusCode,
        OmniMinorCategoryId: omniMinorCategoryId,
        OmniIntermediateCategoryId: omniIntermediateCategoryId
      });
      context.commit('setActiveProducts', response.data.ProductModels);
      context.commit('setProductCount', response.data.ProductCount);

      context.commit('stopLoading');
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('stopLoading');
    }
  },
  async AssociateProductToTag(context, [ProductIds, TagId, AssociationStartDate, AssociationEndDate]) {
    try {
      context.commit('app/startLoading', 'AssociateProductToTag', { root: true });

      await authenticatedAxios.post('/api/tag/AssociateProductToTag', {
        ProductIds: ProductIds,
        TagId: TagId,
        AssociationStartDate: AssociationStartDate,
        AssociationEndDate: AssociationEndDate
      });
      context.commit('setAssociateProductToTagSuccess');

      context.commit('app/stopLoading', 'AssociateProductToTag', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'AssociateProductToTag', { root: true });
    }
  }
};
