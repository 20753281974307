import i18n from '@/i18n';
import store from '../store';
import Localize from '@/components/Localize.vue';
import { memberTypeEnumWeb } from '@/models/GeneratedModels/MemberTypeEnumWeb.js';

export function getRouteName(lang, name) {
  return `${lang}.${name}`;
}

export function isMemberAllowed(route) {
  if (!store.state.app.userInfo || !route || !route.meta) {
    return false;
  }
  return (
    !route.meta.allowMemberTypes ||
    ~route.meta.allowMemberTypes.indexOf(store.state.app.userInfo.MemberType)
  );
}

function getRoutes(lang) {
  return [
    {
      path: '/' + lang,
      name: lang,
      component: Localize,
      beforeEnter(_to, _from, next) {
        i18n.locale = lang;
        next();
      },
      children: [
        {
          path: i18n.t('routes.login', lang),
          name: getRouteName(lang, 'login'),
          component: () => import('@/views/Login.vue'),
          meta: {
            ignoreAuthentication: true,
            hideSidebar: true,
            title: i18n.t('app.titles.login', lang)
          }
        },
        {
          path: i18n.t('routes.products', lang),
          name: getRouteName(lang, 'products'),
          component: () => import('@/views/products/Products.vue'),
          meta: {
            title: i18n.t('product.products', lang)
          }
        },
        // {
        //   path: i18n.t('routes.addProduct', lang),
        //   name: getRouteName(lang, 'addProduct'),
        //   component: () => import('@/views/products/AddProduct.vue'),
        //   meta: {
        //     title: i18n.t('product.addProduct', lang)
        //   }

        // },
        {
          path: i18n.t('routes.productsPublicationStatus', lang),
          name: getRouteName(lang, 'productsPublicationStatus'),
          component: () => import('@/views/products/ProductPublicationStatus.vue'),
          meta: {
            title: i18n.t('routes.productsPublicationStatus', lang)
          }
        },
        {
          path: i18n.t('routes.updateProduct', lang),
          name: getRouteName(lang, 'updateProduct'),
          component: () => import('@/views/products/AddProduct.vue'),
          meta: {
            title: i18n.t('product.updateProduct', lang)
          }
        },
        {
          path: i18n.t('routes.tagManagement', lang),
          name: getRouteName(lang, 'tagManagement'),
          component: () => import('@/views/tags/TagManagement.vue'),
          meta: {
            title: i18n.t('tag.tagManagement', lang),
            allowMemberTypes: [memberTypeEnumWeb.Manager]
          }
        },
        {
          path: i18n.t('routes.productImportation', lang),
          name: getRouteName(lang, 'productImportation'),
          component: () => import('@/views/products/ProductImportation.vue'),
          meta: {
            title: i18n.t('productImportation.productImportation', lang),
            allowMemberTypes: [memberTypeEnumWeb.Manager]
          }
        },
        {
          path: i18n.t('routes.productsSummary', lang),
          name: getRouteName(lang, 'productsSummary'),
          component: () => import('@/views/products/Summary.vue'),
          meta: {
            title: i18n.t('menu.productsSummary', lang),
            allowMemberTypes: [memberTypeEnumWeb.Manager]
          }
        },
        {
          path: i18n.t('routes.error', lang),
          name: getRouteName(lang, 'error'),
          component: () => import('@/views/Error.vue'),
          meta: {
            ignoreAuthentication: true,
            hideSidebar: true,
            title: i18n.t('app.titles.error', lang)
          }
        },
        {
          path: i18n.t('routes.pageNotFound', lang),
          name: getRouteName(lang, 'pageNotFound'),
          component: () => import('@/views/PageNotFound.vue'),
          meta: {
            ignoreAuthentication: true,
            hideSidebar: true,
            title: i18n.t('app.titles.pageNotFound', lang)
          }
        },
        {
          path: '*',
          redirect: i18n.t('routes.pageNotFound', lang)
        }
      ]
    }
  ];
}

export default [
  {
    path: '',
    redirect: { name: i18n.locale || process.env.VUE_APP_I18N_LOCALE }
  },
  ...getRoutes('fr'),
  ...getRoutes('en'),
  {
    path: '*',
    redirect: i18n.t(
      'routes.pageNotFound',
      i18n.locale || process.env.VUE_APP_I18N_LOCALE
    )
  }
];
