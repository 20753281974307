export default class State {
  constructor() {
    this.tags = [];
    this.activeProducts = [];
    this.productCount = 0;
    this.isLoading = false;
    this.isAssociateProductToTagSuccess = {
      isSuccess: false
    };
  }
}
