import axios from 'axios';
import { errorCodeEnumWeb } from '@/models/GeneratedModels/ErrorCodeEnumWeb.js';

export default {
  async LoadOmniMajorCategories(context) {
    if (context.state.omniMajorCategories.length === 0) {
      context.dispatch('GetOmniMajorCategories');
    }
  },
  async LoadOmniIntermediateCategories(context) {
    if (context.state.omniIntermediateCategories.length === 0) {
      context.dispatch('GetOmniIntermediateCategories');
    }
  },
  async LoadOmniMinorCategories(context) {
    if (context.state.omniMinorCategories.length === 0) {
      context.dispatch('GetOmniMinorCategories');
    }
  },
  async GetOmniMajorCategories(context) {
    try {
      context.commit('app/startLoading', 'GetOmniMajorCategories', { root: true });
      let response = await axios.get('/api/category/GetOmniMajorCategories');
      context.commit('setOmniMajorCategories', response.data);
      context.commit('app/stopLoading', 'GetOmniMajorCategories', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetOmniMajorCategories', { root: true });
    }
  },
  async GetOmniIntermediateCategories(context) {
    try {
      context.commit('app/startLoading', 'GetOmniIntermediateCategories', { root: true });
      let response = await axios.get('/api/category/GetOmniIntermediateCategories');
      context.commit('setOmniIntermediateCategories', response.data);
      context.commit('app/stopLoading', 'GetOmniIntermediateCategories', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetOmniIntermediateCategories', { root: true });
    }
  },
  async GetOmniMinorCategories(context) {
    try {
      context.commit('app/startLoading', 'GetOmniMinorCategories', { root: true });
      let response = await axios.get('/api/category/GetOmniMinorCategories');
      context.commit('setOmniMinorCategories', response.data);
      context.commit('app/stopLoading', 'GetOmniMinorCategories', { root: true });
    } catch (e) {
      context.commit('app/addError', errorCodeEnumWeb.GenericError, { root: true });
      context.commit('app/stopLoading', 'GetOmniMinorCategories', { root: true });
    }
  }
};
