import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function cleanupFileName(fileName) {
  return fileName.charAt(0).toLowerCase() + fileName.substr(1);
}

function loadLocaleMessages() {
  const localeModules = require.context('./locales', true, /\.json$/);
  const files = {};
  localeModules.keys().forEach(key => {
    files[key] = localeModules(key);
  });
  const messages = {};
  for (const file in files) {
    const langFile = file.replace('./', '').replace('.json', '').split('/');
    const language = langFile[0];
    const fileName = cleanupFileName(langFile[1]);
    messages[language] = messages[language] || {};
    messages[language][fileName] = files[file];
  }
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  silentFallbackWarn: true
});
