export default class State {
  constructor() {
    this.error = '';
    this.isProductAdded = {
      isUpdated: false
    };
    this.isProductUpdated = {
      isUpdated: false
    };
    this.activeProducts = null;
    this.productCount = 0;
    this.isProductsDeleted = {
      isUpdated: false
    };
    this.errorProductAdded = {
      isUpdated: false
    };
    this.activeProduct = null;
    this.isImportFileCompleted = {
      isCompleted: false
    };
  }
}
