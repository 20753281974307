import OmniMajorCategory from '@/models/GeneratedModels/OmniMajorCategoryModel.js';
import OmniIntermediateCategory from '@/models/GeneratedModels/OmniIntermediateCategoryModel.js';
import OmniMinorCategory from '@/models/GeneratedModels/OmniMinorCategoryModel.js';

export default {
  setOmniMajorCategories(state, categories) {
    state.omniMajorCategories = categories.map(function (category) {
      return new OmniMajorCategory(category);
    });
  },
  setOmniIntermediateCategories(state, categories) {
    state.omniIntermediateCategories = categories.map(function (category) {
      return new OmniIntermediateCategory(category);
    });
  },
  setOmniMinorCategories(state, categories) {
    state.omniMinorCategories = categories.map(function (category) {
      return new OmniMinorCategory(category);
    });
  }
};
